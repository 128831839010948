import React, { useState } from 'react';
import './YAManager.scss';
import { Wallet } from '../code/Wallet';
import YADialog from './YADialog';
import { YieldAggregator } from '../code/contracts/YieldAggregator';
import { appConfig } from '../code/appConfig';
import YARow from './YARow';
import { BigNumber } from 'ethers';

interface YAManagerProps {
  account: string;
  wallet: Wallet;
  usdcPrice: BigNumber;
  giddyPrice: BigNumber;
  tokenPriceData: BigNumber[];
  rewardsPriceData: BigNumber[];
  displaySuccess(message: string): void;
  displayError(message: string): void;
}

export default function YAManager({ account, wallet, usdcPrice, giddyPrice, tokenPriceData, rewardsPriceData, displaySuccess, displayError }:YAManagerProps) {
  const [loadCount, setLoadCount] = useState(0);
  const [yaOpen, setYaOpen] = useState(false);
  const [currentAgg, setCurrentAgg] = useState(null as (YieldAggregator | null));

  async function yaClose(message: string) {
    setYaOpen(false);
    if (message) {
      setLoadCount(loadUserCount => loadUserCount + 1);
      displaySuccess(message);
    }
  }

  function yaClicked(agg: YieldAggregator) {
    setCurrentAgg(agg);
    setYaOpen(true);
  }

  return (
    <div className='yield-manager'>
      {
        appConfig.aggregators.map((agg, index) => {
          return <YARow key={index} account={account} wallet={wallet} yaInfo={agg} usdcPrice={usdcPrice} giddyPrice={giddyPrice} tokenPrice={tokenPriceData[index]} rewardsPrice={rewardsPriceData[index]} loadCount={loadCount} onClick={yaClicked} />;
        })
      }
      <YADialog isOpen={yaOpen} onClose={yaClose} account={account} agg={currentAgg}  />
    </div>
  );
}
