import React, { useEffect, useState } from 'react';
import './App.scss';
import { PriceData, WalletType } from '../code/types';
import ConnectDiaglog from './ConnectDialog';
import { Alert, Snackbar } from '@mui/material';
import ConnectionBar from './ConnectionBar';
import GiddyLogoBlack from '../content/giddy-horizontal-black.svg';
import YAManager from './YAManager';
import useWallet from '../code/useWallet';
import { useQuery } from '@apollo/client';
import { PRICES_QUERY } from '../code/graphql';
import { BigNumber, ethers } from 'ethers';
import { appConfig, getSymbolList } from '../code/appConfig';
import Other from './Other';

function App() {
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [successOpen, setSuccessOpen] = useState(false);
  const [successText, setSuccessText] = useState('');
  const [connectOpen, setConnectOpen] = useState(false);
  const [walletType, setWalletType] = useState((localStorage.getItem('wallet') ?? WalletType.None) as WalletType);
  const [wallet, chainId, account] = useWallet();
  const [usdcPrice, setUsdcPrice] = useState(ethers.constants.Zero);
  const [giddyPrice, setGiddyPrice] = useState(ethers.constants.Zero);
  const [tokenPriceData, setTokenPriceData] = useState(Array(appConfig.aggregators.length).fill(ethers.constants.Zero) as BigNumber[]);
  const [rewardsPriceData, setRewardsPriceData] = useState(Array(appConfig.aggregators.length).fill(ethers.constants.Zero) as BigNumber[]);

  const pricesQuery = useQuery<PriceData>(PRICES_QUERY, { 
    variables: { 
      input: { 
        symbols: getSymbolList()
      } 
    } 
  });

  useEffect(() => {
    if (pricesQuery.data?.prices?.gas?.fastGasPrice) {
      console.log('GAS PRICE SET: ' + pricesQuery.data.prices.gas.fastGasPrice);
      appConfig.gasPrice = ethers.utils.parseUnits(pricesQuery.data.prices.gas.fastGasPrice, 'gwei');
    }
    if (pricesQuery.data?.prices?.tokens) {
      for (let i = 0; i < pricesQuery.data.prices.tokens.length; i++) {
        if (pricesQuery.data.prices.tokens[i].symbol === 'USDC') {
          setUsdcPrice(BigNumber.from(pricesQuery.data.prices.tokens[i].price));
        }
        if (pricesQuery.data.prices.tokens[i].symbol === 'GIDDY') {
          setGiddyPrice(BigNumber.from(pricesQuery.data.prices.tokens[i].price));
        }
      }
      const tokenPrices = Array(appConfig.aggregators.length).fill(ethers.constants.Zero) as BigNumber[];
      const rewardsPrices = Array(appConfig.aggregators.length).fill(ethers.constants.Zero) as BigNumber[];
      for (let i = 0; i < appConfig.aggregators.length; i++) {
        for (let j = 0; j < pricesQuery.data.prices.tokens.length; j++) {
          if (pricesQuery.data.prices.tokens[j].price) {
            if (pricesQuery.data.prices.tokens[j].symbol === appConfig.aggregators[i].tokenSymbol) {
              tokenPrices[i] = BigNumber.from(pricesQuery.data.prices.tokens[j].price);
            }
            if (pricesQuery.data.prices.tokens[j].symbol === appConfig.aggregators[i].rewardsSymbol) {
              rewardsPrices[i] = BigNumber.from(pricesQuery.data.prices.tokens[j].price);
            }
          }
        }
      }
      setTokenPriceData(tokenPrices);
      setRewardsPriceData(rewardsPrices);
    }
  },[pricesQuery.data]);

  useEffect(() => {
    wallet.connect(walletType);
  }, [wallet, walletType]);

  function walletSelected(wallet: WalletType) {
    if (wallet) {
      localStorage.setItem('wallet', wallet);
      setWalletType(wallet);
      setConnectOpen(false);
    }
  }

  function displayError(text: string) {
    setErrorText(text);
    setErrorOpen(true);
  }

  function displaySuccess(text: string) {
    setSuccessText(text);
    setSuccessOpen(true);
  }

  return (
    <div className="app">
      <div className="content">
        <div className="top-bar">
          <div className="top-bar-grid">
            <a href="https://giddy.co/"><GiddyLogoBlack /></a>
            <ConnectionBar
              onConnect={() => { setConnectOpen(true) }} 
              walletType={walletType}
              chainId={chainId}
              account={account} />
          </div>
        </div>
        <YAManager wallet={wallet} account={account} usdcPrice={usdcPrice} giddyPrice={giddyPrice} tokenPriceData={tokenPriceData} rewardsPriceData={rewardsPriceData} displaySuccess={displaySuccess} displayError={displayError} />
        <Other wallet={wallet} account={account} displaySuccess={displaySuccess} displayError={displayError} />
      </div>
      <ConnectDiaglog
        onClose={() => { setConnectOpen(false) }} 
        onSelect={walletSelected} 
        isOpen={connectOpen}
        walletType={walletType} />
      <Snackbar open={errorOpen} autoHideDuration={7000} anchorOrigin={{ vertical:'bottom', horizontal: 'center' }} onClose={() => {setErrorOpen(false)}}>
        <Alert onClose={() => {setErrorOpen(false)}} severity="error" sx={{ width: '100%' }}>
          {errorText}
        </Alert>
      </Snackbar>
      <Snackbar open={successOpen && !errorOpen} autoHideDuration={7000} anchorOrigin={{ vertical:'bottom', horizontal: 'center' }} onClose={() => {setSuccessOpen(false)}}>
        <Alert onClose={() => {setSuccessOpen(false)}} severity="success" sx={{ width: '100%' }}>
          {successText}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default App;