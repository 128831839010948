import { appConfig } from "../appConfig";
import ABI from './YieldAggregator.json';
import { BigNumber, ethers } from "ethers";
import { Wallet } from "../Wallet";
import { YAGlobalSettings, YAInfo } from "../types";

export class YieldAggregator {
  private contract: ethers.Contract;
  private connectedContract: ethers.Contract | null;
  private feeMultiplier = 20;

  public wallet: Wallet;
  public info: YAInfo;
  
  constructor(wallet: Wallet, info: YAInfo) {
    this.wallet = wallet;
    this.info = info;
    this.contract = new ethers.Contract(info.address, ABI.abi, this.wallet.provider);
    this.connectedContract = null;
    this.wallet.addEventListener('connect', () => this.walletConnect());
    this.wallet.addEventListener('disconnect', () => this.walletDisconnect());
  }

  private walletConnect() {
    console.log("WALLET CONNECT: " + this.info.name);
    if (this.wallet?.walletProvider != null) {
      this.connectedContract = this.contract.connect(this.wallet.walletProvider.getSigner());
    }
  }

  private walletDisconnect() {
    this.connectedContract = null;
  }

  public async getDeFiToken(): Promise<string> {
    try {
      const blah = await this.contract.getDeFiToken();
      return blah;
    }
    catch (error: any) {
      console.error('GIDDY.YieldAggregator.getDeFiToken', error);
    }
    return "";
  }

  public async getContractShares(): Promise<BigNumber> {
    try {
      return await this.contract.getContractShares();
    }
    catch (error: any) {
      console.error('GIDDY.YieldAggregator.getContractShares', error);
    }
    return ethers.constants.Zero;
  }

  public async getContractBalance(): Promise<BigNumber> {
    try {
      return await this.contract.getContractBalance();
    }
    catch (error: any) {
      console.error('GIDDY.YieldAggregator.getContractBalance', error);
    }
    return ethers.constants.Zero;
  }

  public async getContractRewards(): Promise<BigNumber> {
    try {
      return await this.contract.getContractRewards();
    }
    catch (error: any) {
      console.error('GIDDY.YieldAggregator.getContractRewards', error);
    }
    return ethers.constants.Zero;
  }

  public async getUserShares(address: string): Promise<BigNumber> {
    try {
      return await this.contract.getUserShares(address);
    }
    catch (error: any) {
      console.error('GIDDY.YieldAggregator.getUserShares', error);
    }
    return ethers.constants.Zero;
  }

  public async getUserBalance(address: string): Promise<BigNumber> {
    try {
      return await this.contract.getUserBalance(address);
    }
    catch (error: any) {
      console.error('GIDDY.YieldAggregator.getUserBalance', error);
    }
    return ethers.constants.Zero;
  }


  public async getGlobalSettings(): Promise<YAGlobalSettings> {
    try {
      return await this.contract.getGlobalSettings();
    }
    catch (error: any) {
      console.error('GIDDY.YieldAggregator.getGlobalSettings', error);
    }
    return { feeAccount: "", earningsFee: ethers.constants.Zero } as YAGlobalSettings;
  }

  public async sharesToValue(shares: BigNumber): Promise<BigNumber> {
    try {
      return await this.contract.sharesToValue(shares);
    }
    catch (error: any) {
      console.error('GIDDY.YieldAggregator.sharesToValue', error);
    }
    return ethers.constants.Zero;
  }

  public async compound(): Promise<string> {
    try {
      if (!this.connectedContract) return 'Not Connected';
      const gasEsitmate = await this.connectedContract.estimateGas.compound();
      const options = { gasPrice: appConfig.gasPrice, gasLimit: gasEsitmate.mul(this.feeMultiplier).div(10) };
      const result = await this.connectedContract.compound(options);
      return result.hash;
    }
    catch (error: any) {
      console.error('GIDDY.YieldAggregator.compound', error);
      return error.message;
    }
  }

  public async depositCalc(value: BigNumber, fap: BigNumber): Promise<[BigNumber, BigNumber]> {
    try {
      return await this.contract.depositCalc(value, fap);
    }
    catch (error: any) {
      console.error('GIDDY.YieldAggregator.depositCalc', error);
    }
    return [ethers.constants.Zero, ethers.constants.Zero];
  }

  public async deposit(signedAuth: string, fap: BigNumber): Promise<string> {
    try {
      if (!this.connectedContract) return 'Not Connected';
      const gasEsitmate = await this.connectedContract.estimateGas.deposit(signedAuth, fap);
      const options = { gasPrice: appConfig.gasPrice, gasLimit: gasEsitmate.mul(this.feeMultiplier).div(10) };
      const result = await this.connectedContract.deposit(signedAuth, fap, options);
      return result.hash;
    }
    catch (error: any) {
      console.error('GIDDY.YieldAggregator.deposit', error);
      return error.message;
    }
  }

  public async depositGiddyCalc(value: BigNumber, fap: BigNumber): Promise<[BigNumber, BigNumber]> {
    try {
      return await this.contract.depositGiddyCalc(value, fap);
    }
    catch (error: any) {
      console.error('GIDDY.YieldAggregator.depositGiddyCalc', error);
    }
    return [ethers.constants.Zero, ethers.constants.Zero];
  }

  public async depositGiddy(approvalRequest: any, signature: any, fap: BigNumber): Promise<string> {
    try {
      if (!this.connectedContract) return 'Not Connected';
      const gasEsitmate = await this.connectedContract.estimateGas.depositGiddy(approvalRequest, signature, fap);
      const options = { gasPrice: appConfig.gasPrice, gasLimit: gasEsitmate.mul(this.feeMultiplier).div(10) };
      const result = await this.connectedContract.depositGiddy(approvalRequest, signature, fap, options);
      return result.hash;
    }
    catch (error: any) {
      console.error('GIDDY.YieldAggregator.depositGiddy', error);
      return error.message;
    }
  }

  public async withdrawCalc(shares: BigNumber, fap: BigNumber): Promise<[BigNumber, BigNumber]> {
    try {
      return await this.contract.withdrawCalc(shares, fap);
    }
    catch (error: any) {
      console.error('GIDDY.YieldAggregator.withdrawCalc', error);
    }
    return [ethers.constants.Zero, ethers.constants.Zero];
  }

  public async withdraw(shares: BigNumber, fap: BigNumber): Promise<string> {
    try {
      if (!this.connectedContract) return 'Not Connected';
      const gasEsitmate = await this.connectedContract.estimateGas.withdraw(shares, fap);
      const options = { gasPrice: appConfig.gasPrice, gasLimit: gasEsitmate.mul(this.feeMultiplier).div(10) };
      const result = await this.connectedContract.withdraw(shares, fap, options);
      return result.hash;
    }
    catch (error: any) {
      console.error('GIDDY.YieldAggregator.withdraw', error);
      return error.message;
    }
  }

  public async withdrawGiddyCalc(shares: BigNumber, fap: BigNumber): Promise<[BigNumber, BigNumber]> {
    try {
      return await this.contract.withdrawGiddyCalc(shares, fap);
    }
    catch (error: any) {
      console.error('GIDDY.YieldAggregator.withdrawGiddyCalc', error);
    }
    return [ethers.constants.Zero, ethers.constants.Zero];
  }

  public async withdrawGiddy(shares: BigNumber, fap: BigNumber): Promise<string> {
    try {
      if (!this.connectedContract) return 'Not Connected';
      const gasEsitmate = await this.connectedContract.estimateGas.withdrawGiddy(shares, fap);
      const options = { gasPrice: appConfig.gasPrice, gasLimit: gasEsitmate.mul(this.feeMultiplier).div(10) };
      const result = await this.connectedContract.withdrawGiddy(shares, fap, options);
      return result.hash;
    }
    catch (error: any) {
      console.error('GIDDY.YieldAggregator.withdrawGiddy', error);
      return error.message;
    }
  }

  public async unstickMoney(): Promise<string> {
    try {
      if (!this.connectedContract) return 'Not Connected';
      const gasEsitmate = await this.connectedContract.estimateGas.unstickMoney();
      const options = { gasPrice: appConfig.gasPrice, gasLimit: gasEsitmate.mul(this.feeMultiplier).div(10) };
      const result = await this.connectedContract.unstickMoney(options);
      return result.hash;
    }
    catch (error: any) {
      console.error('GIDDY.YieldAggregator.unstickMoney', error);
      return error.message;
    }
  }
}