import { Button, Checkbox, TextField } from '@mui/material';
import { ethers } from 'ethers';
import React, { useRef, useState } from 'react';
import { appConfig } from '../code/appConfig';
import { PaperHands } from '../code/contracts/PaperHands';
import { formatTokenValue, parseBigNumber } from '../code/globals';
import { Wallet } from '../code/Wallet';
import './Other.scss';
import {GiddyConfig} from "../code/contracts/GiddyConfig";
import { getErc20TokenAllowance } from '../code/contracts/Erc20';
import { SpikeTest } from '../code/contracts/SpikeTest';

interface PaperHandsProps {
  account: string;
  wallet: Wallet;
  displaySuccess(message: string): void;
  displayError(message: string): void;
}

export default function Other({account, wallet, displaySuccess, displayError}:PaperHandsProps) {
  const paperHands = useRef(new PaperHands(wallet));
  const giddyConfig = useRef(new GiddyConfig(wallet));
  const spike = useRef(new SpikeTest(wallet));
  const [sendAmount, setSendAmount] = useState('');
  const [sendAddress, setSendAddress] = useState('');
  const [doSwap, setDoSwap] = useState(false);
  const [feeAccount, setFeeAccount] = useState('');
  const [earningsFee, setEarningsFee] = useState('');

  async function sendUsdcClicked() {
    if (account && wallet) {
      const value = parseBigNumber(sendAmount, 6);

      const calc = await paperHands.current.transferUsdcCalc(value, value.div(10), doSwap);
      const amountOut = formatTokenValue(calc[0], doSwap ? 18 : 6, false);
      const priceImpact = formatTokenValue(calc[1], 6, false);
      console.log("Amount Out: " + amountOut + ", PriceImpact: " + priceImpact);

      const auth = await wallet.generateSignedAuthUSDC(appConfig.paperHands, value);
      const result = await paperHands.current.transferUsdc(auth, sendAddress, value.div(10), doSwap);
      if (!ethers.utils.isHexString(result)) {
        displayError(result);
      }
      else {
        displaySuccess('Transaction Posted!');
      }
    }
  }

  async function sendGiddyClicked() {
    if (account && wallet) {
      const value = parseBigNumber(sendAmount, 18);

      const calc = await paperHands.current.transferGiddyCalc(value, value.div(10), doSwap);
      const amountOut = formatTokenValue(calc[0], doSwap ? 6 : 18, false);
      const priceImpact = formatTokenValue(calc[1], 6, false);
      console.log("Amount Out: " + amountOut + ", PriceImpact: " + priceImpact);

      const currentAllowance = await getErc20TokenAllowance(appConfig.giddy, account, appConfig.paperHands);
      const auth = await wallet.generateSignedAuthGiddy(appConfig.paperHands, value, currentAllowance);
      const result = await paperHands.current.transferGiddy(auth[1], auth[2], sendAddress, value.div(10), doSwap);
      if (!ethers.utils.isHexString(result)) {
        displayError(result);
      }
      else {
        displaySuccess('Transaction Posted!');
      }
    }
  }

  async function setFeeAccountClicked() {
    if (account && wallet) {
      const result = await giddyConfig.current.setFeeAccount(feeAccount);
      if (!ethers.utils.isHexString(result)) {
        displayError(result);
      }
      else {
        displaySuccess('Fee Account Set!');
      }
    }
  }

  async function setEarningsFeeClicked() {
    if (account && wallet) {
      const fee =  parseBigNumber(earningsFee, 4);
      const result = await giddyConfig.current.setEarningsFee(fee);
      if (!ethers.utils.isHexString(result)) {
        displayError(result);
      }
      else {
        displaySuccess('Earnings Fee Set!');
      }
    }
  }

  async function testButtonClicked() {
    if (account && wallet) {

      

      const result = await spike.current.test();
      if (!ethers.utils.isHexString(result)) {
        displayError(result);
      }
      else {
        displaySuccess('Spike Test Success!');
      }
    }
  }


  return (
    <div className='other'>
      <TextField fullWidth label="Send Amount" value={sendAmount} onChange={(e) => setSendAmount(e.target.value)} />
      <TextField fullWidth label="Send Address" value={sendAddress} onChange={(e) => setSendAddress(e.target.value)} />
      <div>Perform Swap <Checkbox checked={doSwap} onChange={(e) => { setDoSwap(e.target.checked) }} /></div>
      <Button variant="contained" fullWidth onClick={sendUsdcClicked}>Send Usdc</Button>
      <Button variant="contained" fullWidth onClick={sendGiddyClicked}>Send Giddy</Button>
      <TextField fullWidth label="Fee Account" value={feeAccount} onChange={(e) => setFeeAccount(e.target.value)} />
      <Button variant="contained" fullWidth onClick={setFeeAccountClicked}>Set Fee Account</Button>
      <TextField fullWidth label="Earnings Fee" value={earningsFee} onChange={(e) => setEarningsFee(e.target.value)} />
      <Button variant="contained" fullWidth onClick={setEarningsFeeClicked}>Set Earnings Fee</Button>
      <Button variant="contained" fullWidth onClick={testButtonClicked}>Test Button</Button>
    </div>
  );
}
