import { appConfig } from "../appConfig";
import ABI from "./SpikeTest.json";
import { ethers } from "ethers";
import { Wallet } from "../Wallet";

export class SpikeTest {
  private contract: ethers.Contract;
  private connectedContract: ethers.Contract | null;
  private feeMultiplier = 20;

  public wallet: Wallet;
  
  constructor(wallet: Wallet) {
    this.wallet = wallet;
    this.contract = new ethers.Contract(appConfig.paperHands, ABI.abi, this.wallet.provider);
    this.connectedContract = null;
    this.wallet.addEventListener('connect', () => this.walletConnect());
    this.wallet.addEventListener('disconnect', () => this.walletDisconnect());
  }

  private walletConnect() {
    if (this.wallet?.walletProvider != null) {
      this.connectedContract = this.contract.connect(this.wallet.walletProvider.getSigner());
    }
  }

  private walletDisconnect() {
    this.connectedContract = null;
  }

  public async test(): Promise<string> {
    try {
      if (!this.connectedContract) return 'Not Connected';
      const gasEsitmate = await this.connectedContract.estimateGas.test();
      const options = { gasPrice: appConfig.gasPrice, gasLimit: gasEsitmate.mul(this.feeMultiplier).div(10) };
      const result = await this.connectedContract.test(options);
      return result.hash;
    }
    catch (error: any) {
      console.error('GIDDY.SpikeTest.test', error);
      return error.message;
    }
  }
}