import { ethers } from "ethers";
import { EthereumChain, YAInfo } from "./types";

export const thunderfuryConfig = {
  gasPrice: ethers.utils.parseUnits('55', 'gwei'),
  graphqlUrl: 'https://chefapi.giddystaging.com/g',
  rpcUrl: 'https://thunderfury.giddystaging.com',
  usdc: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
  giddy: "0x67eB41A14C0fe5CD701FC9d5A3D6597A72F641a6",
  wMatic: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
  sushiSwapRouter: "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506",
  paperHands: "0x572095013B5eA69E57242E371a1a594Ff68e9EcA",
  giddyConfig: "0x7D1a307dA8928C0086CC9b2c8a7f447e25e9BED4",
  aggregators: [
    { 
      name: 'Gamma USDC WETH Narrow',
      address: '0xB295b4C60D0F9e0C9F0C7B52d84A84A89bf7eD15',
      tokenSymbol: 'aUSDC-WETH',
      tokenAddress: '0x3Cc20A6795c4b57d9817399F68E83e71C8626580',
      tokenDecimals: 18,
      rewardsSymbol: 'QUICK',
      rewardsAddress: '0xB5C064F955D8e7F38fE0460C556a72987494eE17',
      rewardsDecimals: 18,
    },
    { 
      name: 'Gamma USDC MAI',
      address: '0x6CD2aF539235ef982C73B2568c9dc2aC7A98307A',
      tokenSymbol: 'aUSDC-MAI',
      tokenAddress: '0x25B186eEd64ca5FDD1bc33fc4CFfd6d34069BAec',
      tokenDecimals: 18,
      rewardsSymbol: 'QI',
      rewardsAddress: '0x580A84C73811E1839F75d86d75d88cCa0c241fF4',
      rewardsDecimals: 18,
    },
    { 
      name: 'Gamma USDC GIDDY',
      address: '0x19d0F7e8b2003E1891e8C58EE6A28e6c08c1DA65',
      tokenSymbol: 'aUSDC-GIDDY',
      tokenAddress: '0x1DDAe2e33C1d68211C5EAE05948FD298e72C541A',
      tokenDecimals: 18,
      rewardsSymbol: 'GIDDY',
      rewardsAddress: '0x67eB41A14C0fe5CD701FC9d5A3D6597A72F641a6',
      rewardsDecimals: 18,
    }
  ] as YAInfo[],
  network: {
    chainId: '0x539',
    chainName: 'Thunderfury',
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: ['https://thunderfury.giddystaging.com'],
    blockExplorerUrls: ['https://polygonscan.com/']
  } as EthereumChain,
}

export const polygonConfig = {
  gasPrice: ethers.utils.parseUnits('55', 'gwei'),
  graphqlUrl: 'https://chefapi.giddy.co/g',
  rpcUrl: 'https://polygon-mainnet.infura.io/v3/e62586f4e0964dc89d82777a4dd7a1ab',
  usdc: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
  giddy: "0x67eB41A14C0fe5CD701FC9d5A3D6597A72F641a6",
  wMatic: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
  sushiSwapRouter: "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506",
  paperHands: "0x572095013B5eA69E57242E371a1a594Ff68e9EcA",
  giddyConfig: "0x7D1a307dA8928C0086CC9b2c8a7f447e25e9BED4",
  aggregators: [
    { 
      name: 'Giddy 10x Pool', 
      address: '0x7057A9c3F6514Bd034eB9326cE945c6b613E90Ea',
      tokenSymbol: 'GIDDY',
      tokenAddress: '0x67eB41A14C0fe5CD701FC9d5A3D6597A72F641a6',
      tokenDecimals: 18,
      rewardsSymbol: 'GIDDY',
      rewardsAddress: '0x67eB41A14C0fe5CD701FC9d5A3D6597A72F641a6',
      rewardsDecimals: 18,
    },
    { 
      name: 'Giddy LP 40x Pool',
      address: '0xe334DFc70819c7e72a8a59D2c27b64b2014F4Ed4',
      tokenSymbol: 'GIDDY-USDC',
      tokenAddress: '0xDE990994309BC08E57aca82B1A19170AD84323E8',
      tokenDecimals: 18,
      rewardsSymbol: 'GIDDY',
      rewardsAddress: '0x67eB41A14C0fe5CD701FC9d5A3D6597A72F641a6',
      rewardsDecimals: 18,
    },
    { 
      name: 'Quick Dragons Lair', 
      address: '0x91CFa70a1a124955F32A8f14B8fbDDc6bd46a89e',
      tokenSymbol: 'QUICK',
      tokenAddress: '0x831753dd7087cac61ab5644b308642cc1c33dc13',
      tokenDecimals: 18,
      rewardsSymbol: 'QUICK',
      rewardsAddress: '0x831753dd7087cac61ab5644b308642cc1c33dc13',
      rewardsDecimals: 18,
    },
    { 
      name: 'New Quick Dragons Lair', 
      address: '0xe638E3261fd7Dad45dD0250d13611D7Fc63DdFDD',
      tokenSymbol: 'QUICK-V2',
      tokenAddress: '0xB5C064F955D8e7F38fE0460C556a72987494eE17',
      tokenDecimals: 18,
      rewardsSymbol: 'QUICK-V2',
      rewardsAddress: '0xB5C064F955D8e7F38fE0460C556a72987494eE17',
      rewardsDecimals: 18,
    },
    { 
      name: 'Aave Usdc', 
      address: '0xEcd55c090Ef6B63074f911D51005a65E1b8B1079',
      tokenSymbol: 'USDC',
      tokenAddress: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
      tokenDecimals: 6,
      rewardsSymbol: 'USDC',
      rewardsAddress: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
      rewardsDecimals: 6,
    },
    { 
      name: 'Aave wMatic', 
      address: '0x9aDbc84B9d6F810FCA43079FC1A0dDfBB56c59dd',
      tokenSymbol: 'WMATIC',
      tokenAddress: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
      tokenDecimals: 18,
      rewardsSymbol: 'WMATIC',
      rewardsAddress: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
      rewardsDecimals: 18,
    },
    { 
      name: 'AAVE Balancer',
      address: '0xf1872155E88255093d2Aec96a1a3ce30155e6D89',
      tokenSymbol: 'BAL',
      tokenAddress: '0x9a71012B13CA4d3D0Cdc72A177DF3ef03b0E76A3',
      tokenDecimals: 18,
      rewardsSymbol: 'BAL',
      rewardsAddress: '0x9a71012B13CA4d3D0Cdc72A177DF3ef03b0E76A3',
      rewardsDecimals: 18,
    },
    { 
      name: 'AAVE Sushi V3',
      address: '0x995671e471f7a5b6f205668048A665B73ef71F9C',
      tokenSymbol: 'SUSHI',
      tokenAddress: '0x0b3F868E0BE5597D5DB7fEB59E1CADBb0fdDa50a',
      tokenDecimals: 18,
      rewardsSymbol: 'SUSHI',
      rewardsAddress: '0x0b3F868E0BE5597D5DB7fEB59E1CADBb0fdDa50a',
      rewardsDecimals: 18,
    },
    { 
      name: 'AAVE Curve V3',
      address: '0xc7A8863992316524c4Bef75223EdC32c8dF96C23',
      tokenSymbol: 'CRV',
      tokenAddress: '0x172370d5Cd63279eFa6d502DAB29171933a610AF',
      tokenDecimals: 18,
      rewardsSymbol: 'CRV',
      rewardsAddress: '0x172370d5Cd63279eFa6d502DAB29171933a610AF',
      rewardsDecimals: 18,
    },
    { 
      name: 'Curve aTriCrypto3', 
      address: '0xaC037312c959431e890b2fa47A7332d2F5f59ECB',
      tokenSymbol: 'crvUSDBTCETH',
      tokenAddress: '0xdAD97F7713Ae9437fa9249920eC8507e5FbB23d3',
      tokenDecimals: 18,
      rewardsSymbol: 'CRV',
      rewardsAddress: '0x172370d5Cd63279eFa6d502DAB29171933a610AF',
      rewardsDecimals: 18,
    },
    { 
      name: 'Curve Eurt Usd', 
      address: '0xB529E51eFC843ABf902c3E95b7BB9b979D1247f2',
      tokenSymbol: 'crvEURTUSD',
      tokenAddress: '0x600743B1d8A96438bD46836fD34977a00293f6Aa',
      tokenDecimals: 18,
      rewardsSymbol: 'CRV',
      rewardsAddress: '0x172370d5Cd63279eFa6d502DAB29171933a610AF',
      rewardsDecimals: 18,
    },
    { 
      name: 'Bifi Maxi', 
      address: '0x9ae4b5F37aAB2915e2eFDEa185e5137341E0C5ed',
      tokenSymbol: 'BIFI',
      tokenAddress: '0xFbdd194376de19a88118e84E279b977f165d01b8',
      tokenDecimals: 18,
      rewardsSymbol: 'WMATIC',
      rewardsAddress: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
      rewardsDecimals: 18,
    },
    { 
      name: 'Balancer WETH USDC WBTC',
      address: '0x28C3b1f02ba8F5017cd32D57db5a6E120Da44427',
      tokenSymbol: 'BPTC',
      tokenAddress: '0x0F09F70Ed59202c77aC667f574A5f79bC65CeA48',
      tokenDecimals: 18,
      rewardsSymbol: 'BAL',
      rewardsAddress: '0x9a71012B13CA4d3D0Cdc72A177DF3ef03b0E76A3',
      rewardsDecimals: 18,
    },
    { 
      name: 'Balancer Staked Matic',
      address: '0x4011305ddC5aefC61802fF52cA52711B956b3924',
      tokenSymbol: 'B-stMATIC-STABLE-gauge-V2',
      tokenAddress: '0x2Aa6fB79EfE19A3fcE71c46AE48EFc16372ED6dD',
      tokenDecimals: 18,
      rewardsSymbol: 'BAL',
      rewardsAddress: '0x9a71012B13CA4d3D0Cdc72A177DF3ef03b0E76A3',
      rewardsDecimals: 18,
    },
    { 
      name: 'Balancer MaticX',
      address: '0x1767a72C8b49Bf88C3874C8Ce12351E03296EB98',
      tokenSymbol: 'B-MaticX-STABLE-V2',
      tokenAddress: '0xdFFe97094394680362Ec9706a759eB9366d804C2',
      tokenDecimals: 18,
      rewardsSymbol: 'BAL',
      rewardsAddress: '0x9a71012B13CA4d3D0Cdc72A177DF3ef03b0E76A3',
      rewardsDecimals: 18,
    },
    { 
      name: 'Balancer Boosted Aave USD',
      address: '0xCBB41Dd94845cE0f5Da35797F3E02abbdC68dCd9',
      tokenSymbol: 'bb-am-usd',
      tokenAddress: '0x48e6B98ef6329f8f0A30eBB8c7C960330d648085',
      tokenDecimals: 18,
      rewardsSymbol: 'BAL',
      rewardsAddress: '0x9a71012B13CA4d3D0Cdc72A177DF3ef03b0E76A3',
      rewardsDecimals: 18,
    },
    { 
      name: 'DFYN',
      address: '0x7F8fB5b1F21E440e6e8394C2f4a95fa963da5e92',
      tokenSymbol: 'DFYN',
      tokenAddress: '0xC168E40227E4ebD8C1caE80F7a55a4F0e6D66C97',
      tokenDecimals: 18,
      rewardsSymbol: 'DFYN',
      rewardsAddress: '0xC168E40227E4ebD8C1caE80F7a55a4F0e6D66C97',
      rewardsDecimals: 18,
    },
    { 
      name: 'Sushi USDC ETH LP',
      address: '0xF055388463FDB517A68Ee0fa395C428Daef8b9b8',
      tokenSymbol: 'GIDDY-USDC',
      tokenAddress: '0x34965ba0ac2451A34a0471F04CCa3F990b8dea27',
      tokenDecimals: 18,
      rewardsSymbol: 'SUSHI',
      rewardsAddress: '0x0b3F868E0BE5597D5DB7fEB59E1CADBb0fdDa50a',
      rewardsDecimals: 18,
    },
    { 
      name: 'Sushi USDC GIDDY LP',
      address: '0xFCf3a8e51F764ebBc352C89ED5186176cFA7B35f',
      tokenSymbol: 'GIDDY-USDC',
      tokenAddress: '0xDE990994309BC08E57aca82B1A19170AD84323E8',
      tokenDecimals: 18,
      rewardsSymbol: 'GIDDY-USDC',
      rewardsAddress: '0xDE990994309BC08E57aca82B1A19170AD84323E8',
      rewardsDecimals: 18,
    },
    { 
      name: 'Gains GNS YA',
      address: '0xfdb9Ac89981eb33815321bB51e07b20735BB5dF9',
      tokenSymbol: 'GNS',
      tokenAddress: '0x34965ba0ac2451A34a0471F04CCa3F990b8dea27',
      tokenDecimals: 18,
      rewardsSymbol: 'DAI',
      rewardsAddress: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
      rewardsDecimals: 18,
    },
    { 
      name: 'Gamma USDC WETH Narrow',
      address: '0xB295b4C60D0F9e0C9F0C7B52d84A84A89bf7eD15',
      tokenSymbol: 'aUSDC-WETH',
      tokenAddress: '0x3Cc20A6795c4b57d9817399F68E83e71C8626580',
      tokenDecimals: 18,
      rewardsSymbol: 'QUICK',
      rewardsAddress: '0xB5C064F955D8e7F38fE0460C556a72987494eE17',
      rewardsDecimals: 18,
    },
    { 
      name: 'Gamma USDC MAI',
      address: '0x6CD2aF539235ef982C73B2568c9dc2aC7A98307A',
      tokenSymbol: 'aUSDC-MAI',
      tokenAddress: '0x25B186eEd64ca5FDD1bc33fc4CFfd6d34069BAec',
      tokenDecimals: 18,
      rewardsSymbol: 'QI',
      rewardsAddress: '0x580A84C73811E1839F75d86d75d88cCa0c241fF4',
      rewardsDecimals: 18,
    },
    { 
      name: 'Gamma USDC GIDDY',
      address: '0x4d5FE46b94B14E18f66EA8da734099Ee8471fFB3',
      tokenSymbol: 'aUSDC-GIDDY',
      tokenAddress: '0x1DDAe2e33C1d68211C5EAE05948FD298e72C541A',
      tokenDecimals: 18,
      rewardsSymbol: 'GIDDY',
      rewardsAddress: '0x67eB41A14C0fe5CD701FC9d5A3D6597A72F641a6',
      rewardsDecimals: 18,
    }
  ] as YAInfo[],
  network: {
    chainId: '0x89',
    chainName: 'Polygon Mainnet',
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: [
      'https://polygon-rpc.com/',
      'https://rpc-mainnet.matic.network',
      'https://matic-mainnet.chainstacklabs.com',
      'https://rpc-mainnet.maticvigil.com',
      'https://rpc-mainnet.matic.quiknode.pro',
      'https://matic-mainnet-full-rpc.bwarelabs.com'],
    blockExplorerUrls: ['https://polygonscan.com/']
  } as EthereumChain,
}

export function getSymbolList(): string[] {
  const ret = [] as string[];
  appConfig.aggregators.map((agg) => {
    if (!ret.includes(agg.tokenSymbol)) {
      ret.push(agg.tokenSymbol);
    }
    if (!ret.includes(agg.rewardsSymbol)) {
      ret.push(agg.rewardsSymbol);
    }
  });
  return ret;
}

export const appConfig = thunderfuryConfig;