import React, { useEffect, useRef, useState } from 'react';
import './YARow.scss';
import { Wallet } from '../code/Wallet';
import { YAGlobalSettings, YAInfo } from '../code/types';
import { YieldAggregator } from '../code/contracts/YieldAggregator';
import { BigNumber, ethers } from 'ethers';
import { Button } from '@mui/material';
import { getErc20TokenBalance } from '../code/contracts/Erc20';
import { appConfig } from '../code/appConfig';
import FormatToken from './FormatToken';
import { formatTokenValue } from '../code/globals';

interface YARowProps {
  account: string;
  wallet: Wallet;
  yaInfo: YAInfo;
  usdcPrice: BigNumber;
  giddyPrice: BigNumber;
  tokenPrice: BigNumber;
  rewardsPrice: BigNumber;
  loadCount: number;
  onClick(agg: YieldAggregator): void;
}

export default function YARow({ account, wallet, yaInfo, usdcPrice, giddyPrice, tokenPrice, rewardsPrice, loadCount, onClick }: YARowProps) {
  const agg = useRef(new YieldAggregator(wallet, yaInfo));
  const [contractShares, setContractShares] = useState(ethers.constants.Zero);
  const [contractBalance, setContractBalance] = useState(ethers.constants.Zero);
  const [contractRewards, setContractRewards] = useState(ethers.constants.Zero);
  const [userShares, setUserShares] = useState(ethers.constants.Zero);
  const [userBalance, setUserBalance] = useState(ethers.constants.Zero);
  const [usdcBalance, setUsdcBalance] = useState(ethers.constants.Zero);
  const [giddyBalance, setGiddyBalance] = useState(ethers.constants.Zero);
  const [tokenBalance, setTokenBalance] = useState(ethers.constants.Zero);
  const [rewardsBalance, setRewardsBalance] = useState(ethers.constants.Zero);
  const [globalSettings, setGlobalSettings] = useState({ feeAccount: "",  earningsFee: ethers.constants.Zero } as YAGlobalSettings);

  useEffect(() => {
    async function loadData() {
      const results = await Promise.all([
        agg.current.getContractShares(),
        agg.current.getContractBalance(),
        agg.current.getContractRewards(),
        agg.current.getGlobalSettings(),
        getErc20TokenBalance(appConfig.usdc, yaInfo.address),
        getErc20TokenBalance(appConfig.giddy, yaInfo.address),
        getErc20TokenBalance(yaInfo.tokenAddress, yaInfo.address),
        getErc20TokenBalance(yaInfo.rewardsAddress, yaInfo.address),
      ]);
      setContractShares(results[0]);
      setContractBalance(results[1]);
      setContractRewards(results[2]);
      setGlobalSettings(results[3]);
      setUsdcBalance(results[4]);
      setGiddyBalance(results[5]);
      setTokenBalance(results[6]);
      setRewardsBalance(results[7]);
      if (account) {
        const results = await Promise.all([
          agg.current.getUserShares(account),
          agg.current.getUserBalance(account),
        ]);
        setUserShares(results[0]);
        setUserBalance(results[1]);
      }
    }
    loadData();
  }, [loadCount, account, yaInfo]);

  function rowClicked() {
    onClick(agg.current);
  }

  return (
    <div className="ya-row">
      <div>
        <Button variant="contained" onClick={rowClicked} >{yaInfo.name}</Button>
        <a className="contract-link" href={'https://polygonscan.com/address/' + yaInfo.address}>{yaInfo.address}</a>
      </div>
      <div className="ya-box">
        <div className="ya-info"><b>Total Shares:</b> <FormatToken value={contractShares} decimals={yaInfo.tokenDecimals + 10} /></div>
        <div className="ya-info"><b>Total Value Locked ({yaInfo.tokenSymbol}): </b><FormatToken value={contractBalance} decimals={yaInfo.tokenDecimals} showValue={true} price={tokenPrice} /></div>
        <div className="ya-info"><b>Pending Rewards ({yaInfo.rewardsSymbol}):</b> <FormatToken value={contractRewards} decimals={yaInfo.rewardsDecimals} showValue={true} price={rewardsPrice} /></div>
        <div className="ya-info"><b>User Shares:</b> <FormatToken value={userShares} decimals={yaInfo.tokenDecimals + 10} /></div>
        <div className="ya-info"><b>User Staked ({yaInfo.tokenSymbol}):</b> <FormatToken value={userBalance} decimals={yaInfo.tokenDecimals} showValue={true} price={tokenPrice} /></div>
        <div className="ya-info"><b>Contract USDC:</b> <FormatToken value={usdcBalance} decimals={6} showValue={true} price={usdcPrice} /></div>
        <div className="ya-info"><b>Contract GIDDY:</b> <FormatToken value={giddyBalance} decimals={18} showValue={true} price={giddyPrice} /></div>
        { (yaInfo.tokenSymbol !== 'GIDDY' && yaInfo.tokenSymbol !== 'USDC') && <div className="ya-info"><b>Contract {yaInfo.tokenSymbol}:</b> <FormatToken value={tokenBalance} decimals={yaInfo.tokenDecimals} showValue={true} price={tokenPrice} /></div> }
        { (yaInfo.rewardsSymbol !== 'GIDDY' && yaInfo.rewardsSymbol !== 'USDC' && yaInfo.rewardsSymbol !== yaInfo.tokenSymbol) && <div className="ya-info"><b>Contract {yaInfo.rewardsSymbol}:</b> <FormatToken value={rewardsBalance} decimals={yaInfo.rewardsDecimals} showValue={true} price={rewardsPrice} /></div> }
        <div className="ya-info"><b>Fee Account:</b> { globalSettings.feeAccount }</div>   
        <div className="ya-info"><b>Earnings Fee:</b> { formatTokenValue(globalSettings.earningsFee, 4, false) }</div>
      </div>
    </div>
  );
}
