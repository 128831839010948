import React, { useEffect, useRef, useState } from 'react';
import './YADialog.scss';
import Dialog from '@mui/material/Dialog';
import { Alert, Button, FormControlLabel, IconButton, Radio, RadioGroup, TextField } from '@mui/material';
import { BigNumber, ethers } from 'ethers';
import { LoadingButton } from '@mui/lab';
import { formatTokenValue, parseBigNumber } from '../code/globals';
import { appConfig } from '../code/appConfig';
import CloseIcon from 'mdi-react/CloseIcon';
import CheckIcon from 'mdi-react/CheckIcon';
import { YieldAggregator } from '../code/contracts/YieldAggregator';
import { getErc20TokenAllowance, getErc20TokenBalance } from '../code/contracts/Erc20';

interface YADialogProps {
  isOpen: boolean;
  account: string;
  agg: YieldAggregator | null;
  onClose(message:string): void;
}

export default function YADialog({isOpen, agg, account, onClose}:YADialogProps) {
  const [radioText, setRadioText] = useState('stake');
  const [farmError, setDialogError] = useState('');
  const [farmSuccess, setDialogSuccess] = useState('');
  const [loading, setLoading] = useState(true);

  const [availableUsdc, setAvailableUsdc] = useState(ethers.constants.Zero);
  const [availableGiddy, setAvailableGiddy] = useState(ethers.constants.Zero);
  const [staked, setStaked] = useState(ethers.constants.Zero);
  const [shares, setShares] = useState(ethers.constants.Zero);

  const [compoundText, setCompoundText] = useState('');

  const [depositUsdcError, setDepositUsdcError] = useState('');
  const [depositUsdcLabel, setDepositUsdcLabel] = useState('');
  const [depositUsdcAmount, setDepositUsdcAmount] = useState('');
  const [approveUsdcDisabled, setApproveUsdcDisabled] = useState(true);
  const [approveUsdcText, setApproveUsdcText] = useState('');
  const [stakeUsdcDisabled, setStakeUsdcDisabled] = useState(true);
  const [stakeUsdcText, setStakeUsdcText] = useState('');
  const [depositGiddyError, setDepositGiddyError] = useState('');
  const [depositGiddyLabel, setDepositGiddyLabel] = useState('');
  const [depositGiddyAmount, setDepositGiddyAmount] = useState('');
  const [approveGiddyDisabled, setApproveGiddyDisabled] = useState(true);
  const [approveGiddyText, setApproveGiddyText] = useState('');
  const [stakeGiddyDisabled, setStakeGiddyDisabled] = useState(true);
  const [stakeGiddyText, setStakeGiddyText] = useState('');

  const [withdrawError, setWithdrawError] = useState('');
  const [withdrawLabel, setWithdrawLabel] = useState('');
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [unstakeDisabled, setUnstakeDisabled] = useState(true);
  const [unstakeUsdcText, setUnstakeUsdcText] = useState('');
  const [unstakeGiddyText, setUnstakeGiddyText] = useState('');

  const [unstickText, setUnstickText] = useState('');

  const signedAuthUsdc = useRef('');
  const signedAuthGiddy = useRef(null as any);

  useEffect(() => {
    if (isOpen) {
      setRadioText('stake');
      setLoading(true);
      setAvailableUsdc(ethers.constants.MaxUint256);
      setAvailableGiddy(ethers.constants.MaxUint256);
      setStaked(ethers.constants.MaxUint256);
      setShares(ethers.constants.MaxUint256);
      setDepositUsdcError('');
      setDepositUsdcLabel('');
      setDepositUsdcAmount('');
      setDepositGiddyError('');
      setDepositGiddyLabel('');
      setDepositGiddyAmount('');
      setWithdrawError('');
      setWithdrawLabel('');
      setWithdrawAmount('');
      setDialogError('');
      setDialogSuccess('');

      setCompoundText('Compound')
      setApproveUsdcDisabled(true);
      setApproveUsdcText('Approve USDC');
      setStakeUsdcDisabled(true);
      setStakeUsdcText('Stake UDSC');
      setApproveGiddyDisabled(true);
      setApproveGiddyText('Approve GIDDY');
      setStakeGiddyDisabled(true);
      setStakeGiddyText('Stake GIDDY');
      setUnstakeDisabled(true);
      setUnstakeUsdcText('Unstake USDC');
      setUnstakeGiddyText('Unstake GIDDY');
      setUnstickText('Unstick Money');

      const loadData = async () => {
        if (agg && account) {
          const results = await Promise.all([
            getErc20TokenBalance(appConfig.usdc, account),
            getErc20TokenBalance(appConfig.giddy, account),
            agg.getUserShares(account),
          ]);
          setAvailableUsdc(results[0]);
          setAvailableGiddy(results[1]);
          setShares(results[2]);
          setLoading(false);
        }
      }
      loadData();
    }
  }, [isOpen, agg, account]);

  async function compoundClicked() {
    if (account && agg) {
      setCompoundText('Confirming');
      const compoundResult = await agg.compound();
      if (!ethers.utils.isHexString(compoundResult)) {
        setDialogError(compoundResult);
      }
      else {
        setCompoundText('Compounding');
        const compoundSuccess = await agg.wallet.waitForTransactionStatus(compoundResult, 1, 1000, 300);
        if (!compoundSuccess) {
          setDialogError('Compound unsuccessful, transaction failed');
        }
        else {
          setDialogSuccess('Giddy Up! Successfully Compounded');
        }
      }
    }
    setCompoundText('Compound');
  }

  function onRadioChange(event: any) {
    setRadioText(event.target.value);
  }

  function depositUsdcChange(value: string) {
    setDepositUsdcLabel('');
    setDepositUsdcError('');
    setDialogError('');
    setDialogSuccess('');
    setApproveUsdcDisabled(true);
    setApproveUsdcText('Approve USDC');
    setStakeUsdcDisabled(true);
    setStakeUsdcText('Stake USDC');
    signedAuthUsdc.current = '';

    value = value.trim().replaceAll(',', '');
    if (value.startsWith('.')) value = '0' + value;

    setDepositUsdcAmount(value);
    if (value.length > 0) {
      const amount = parseBigNumber(value, 6);

      agg?.depositCalc(amount, BigNumber.from(1e5)).then((value)=>{
        console.log("0: " + value[0] + ", 1: " + value[1]);
        setDepositUsdcLabel(formatTokenValue(value[1], 4, true) + "% - " + formatTokenValue(value[0], agg.info.tokenDecimals, true));
      });

      if (!amount.gt(ethers.constants.Zero)) {
        setDepositUsdcError('Invalid Value');
      }
      else {
        if (amount.gt(availableUsdc)) {
          setDepositUsdcError("Amount exceeds funds available");
        }
        else {
          setApproveUsdcDisabled(false);
        }
      }
    }
  }

  function depositGiddyChange(value: string) {
    setDepositGiddyError('');
    setDepositGiddyLabel('');
    setDialogError('');
    setDialogSuccess('');
    setApproveGiddyDisabled(true);
    setApproveGiddyText('Approve GIDDY');
    setStakeGiddyDisabled(true);
    setStakeGiddyText('Stake GIDDY');
    signedAuthGiddy.current = null;

    value = value.trim().replaceAll(',', '');
    if (value.startsWith('.')) value = '0' + value;

    setDepositGiddyAmount(value);
    if (value.length > 0) {
      const amount = parseBigNumber(value, 18);

      agg?.depositGiddyCalc(amount, ethers.constants.WeiPerEther).then((value)=>{
        setDepositGiddyLabel(formatTokenValue(value[1], 4, true) + "% - " + formatTokenValue(value[0], agg.info.tokenDecimals, true));
      });

      if (!amount.gt(ethers.constants.Zero)) {
        setDepositGiddyError('Invalid Value');
      }
      else {
        if (amount.gt(availableGiddy)) {
          setDepositGiddyError("Amount exceeds funds available");
        }
        else {
          setApproveGiddyDisabled(false);
        }
      }
    }
  }

  function depositUsdcMaxClicked() {
    depositUsdcChange(formatTokenValue(availableUsdc, 6, false));
  }

  function depositGiddyMaxClicked() {
    depositGiddyChange(formatTokenValue(availableGiddy, 18, false));
  }

  async function aprroveUsdcClicked() {
    setDialogError('');
    setDialogSuccess('');
    if (!agg) {
      setDialogError('Yield aggregator not found');
    }
    else {
      if (!account) {
        setDialogError('Wallet account not found');
      }
      else {
        const amount = parseBigNumber(depositUsdcAmount, 6);
        if (!amount.gt(ethers.constants.Zero)) {
          setDialogError('Invalid amount');
        }
        else
        {
          setLoading(true);
          setApproveUsdcText('Confirming');
          const signedAuthResult = await agg.wallet.generateSignedAuthUSDC(agg.info.address, amount);
          if (!signedAuthResult) {
            setDialogError('Error signing approval');
            setApproveUsdcText('Approve USDC');
          }
          else {
            signedAuthUsdc.current = signedAuthResult;
            setApproveUsdcText('Approved for ' + formatTokenValue(amount, 6));
            setApproveUsdcDisabled(true);
            setStakeUsdcDisabled(false);
            setDialogSuccess('Your deposit amount has been approved. Stake to complete your transaction.');
          }
          
          setLoading(false);
        }
      }
    }
  }

  async function aprroveGiddyClicked() {
    setDialogError('');
    setDialogSuccess('');
    if (!agg) {
      setDialogError('Yield aggregator not found');
    }
    else {
      if (!account) {
        setDialogError('Wallet account not found');
      }
      else {
        const amount = parseBigNumber(depositGiddyAmount, 18);
        if (!amount.gt(ethers.constants.Zero)) {
          setDialogError('Invalid amount');
        }
        else
        {
          setLoading(true);
          setApproveGiddyText('Confirming');
          const currentAllowance = await getErc20TokenAllowance(appConfig.giddy, account, agg.info.address);
          const signedAuthResult = await agg.wallet.generateSignedAuthGiddy(agg.info.address, amount, currentAllowance);
          if (signedAuthResult[0]) {
            setDialogError('Error signing approval, ' + signedAuthResult[0]);
            setApproveGiddyText('Approve GIDDY');
          }
          else {
            signedAuthGiddy.current = signedAuthResult;
            setApproveGiddyText('Approved for ' + formatTokenValue(amount, 18));
            setApproveGiddyDisabled(true);
            setStakeGiddyDisabled(false);
            setDialogSuccess('Your deposit amount has been approved. Stake to complete your transaction.');
          }
          
          setLoading(false);
        }
      }
    }
  }

  async function stakeUsdcClicked() {
    setDialogError('');
    setDialogSuccess('');
    if (!agg) {
      setDialogError('Yield aggregator not found');
    }
    else {
      if (!account) {
        setDialogError('Wallet account not found');
      }
      else {
        if (!signedAuthUsdc) {
          setDialogError('Invalid signature');
        }
        else
        {
          setLoading(true);
          setStakeUsdcText('Confirming');
          const depositResult = await agg.deposit(signedAuthUsdc.current, BigNumber.from(1e5));
          if (!ethers.utils.isHexString(depositResult)) {
            setStakeUsdcText('Stake USDC');
            setDialogError(depositResult);
          }
          else {
            setStakeUsdcText('Pending');
            const depositSuccess = await agg.wallet.waitForTransactionStatus(depositResult, 1, 1000, 60);
            if (!depositSuccess) {
              setStakeUsdcText('Stake USDC');
              setDialogError('Stake unsuccessful, transaction failed');
            }
            else {
              onClose('Giddy Up! Successfully Staked');
            }
          }
  
          setLoading(false);
        }
      }
    }
  }

  async function stakeGiddyClicked() {
    setDialogError('');
    setDialogSuccess('');
    if (!agg) {
      setDialogError('Yield aggregator not found');
    }
    else {
      if (!account) {
        setDialogError('Wallet account not found');
      }
      else {
        if (!signedAuthGiddy) {
          setDialogError('Invalid signature');
        }
        else
        {
          setLoading(true);
          setStakeGiddyText('Confirming');
          const depositResult = await agg.depositGiddy(signedAuthGiddy.current[1], signedAuthGiddy.current[2], ethers.constants.WeiPerEther);
          if (!ethers.utils.isHexString(depositResult)) {
            setStakeGiddyText('Stake');
            setDialogError(depositResult);
          }
          else {
            setStakeGiddyText('Pending');
            const depositSuccess = await agg.wallet.waitForTransactionStatus(depositResult, 1, 1000, 60);
            if (!depositSuccess) {
              setStakeGiddyText('Stake');
              setDialogError('Stake unsuccessful, transaction failed');
            }
            else {
              onClose('Giddy Up! Successfully Staked');
            }
          }
  
          setLoading(false);
        }
      }
    }
  }

  function withdrawChange(value: string) {
    setWithdrawError('');
    setDialogError('');
    setDialogSuccess('');
    setWithdrawLabel('');
    setUnstakeDisabled(true);
    setUnstakeUsdcText('Unstake USDC');
    setUnstakeGiddyText('Unstake GIDDY');
    value = value.trim().replaceAll(',', '');
    if (value.startsWith('.')) value = '0' + value;
    setWithdrawAmount(value);
    if (value.length > 0) {
      const amount = parseBigNumber(value, (agg?.info.tokenDecimals ?? 0) + 10);
      if (!amount.gt(ethers.constants.Zero)) {
        setWithdrawError('Invalid Value');
      }
      else {
        if (amount.gt(shares)) {
          setWithdrawError("Amount exceeds shares available");
        }
        else {
          setUnstakeDisabled(false);
        }
      }
    }
  }

  function withdrawMaxClicked() {
    withdrawChange(formatTokenValue(shares, (agg?.info.tokenDecimals ?? 0) + 10, false));
  }

  async function unstakeUsdcClicked() {
    setDialogError('');
    setDialogSuccess('');
    setWithdrawLabel('');
    if (!agg) {
      setDialogError('Yield aggregator not found');
    }
    else {
      if (!account) {
        setDialogError('Wallet account not found');
      }
      else {
        const amount = parseBigNumber(withdrawAmount, agg.info.tokenDecimals + 10);
        if (!amount.gt(ethers.constants.Zero)) {
          setDialogError('Invalid amount');
        }
        else
        {
          agg?.withdrawCalc(amount, BigNumber.from(1e5)).then((value)=>{
            console.log("YA: " + value[0] + " - " + value[1]);
            setWithdrawLabel(formatTokenValue(value[1], 4, true) + "% - " + formatTokenValue(value[0], 6, true));
          });

          setLoading(true);
          setUnstakeUsdcText('Confirming');
          const withdrawResult = await agg.withdraw(amount, BigNumber.from(1e5));
          if (!ethers.utils.isHexString(withdrawResult)) {
            setUnstakeUsdcText('Unstake USDC');
            setDialogError(withdrawResult);
          }
          else {
            setUnstakeUsdcText('Pending');
            const unstakeSuccess = await agg.wallet.waitForTransactionStatus(withdrawResult, 1, 1000, 300);
            if (!unstakeSuccess) {
              setUnstakeUsdcText('Unstake USDC');
              setDialogError('Unstake unsuccessful, transaction failed');
            }
            else {
              onClose('Successfully unstaked ' + formatTokenValue(amount, agg.info.tokenDecimals + 10) + ' Shares');
            }
          }
          setLoading(false);
        }
      }
    }
  }

  async function unstakeGiddyClicked() {
    setDialogError('');
    setDialogSuccess('');
    setWithdrawLabel('');
    if (!agg) {
      setDialogError('Yield aggregator not found');
    }
    else {
      if (!account) {
        setDialogError('Wallet account not found');
      }
      else {
        const amount = parseBigNumber(withdrawAmount, agg.info.tokenDecimals + 10);
        if (!amount.gt(ethers.constants.Zero)) {
          setDialogError('Invalid amount');
        }
        else
        {
          agg?.withdrawGiddyCalc(amount, ethers.constants.WeiPerEther).then((value)=>{
            setWithdrawLabel(formatTokenValue(value[1], 4, true) + "% - " + formatTokenValue(value[0], 18, true));
          });

          setLoading(true);
          setUnstakeGiddyText('Confirming');
          const withdrawResult = await agg.withdrawGiddy(amount, ethers.constants.WeiPerEther);
          if (!ethers.utils.isHexString(withdrawResult)) {
            setUnstakeGiddyText('Unstake GIDDY');
            setDialogError(withdrawResult);
          }
          else {
            setUnstakeGiddyText('Pending');
            const unstakeSuccess = await agg.wallet.waitForTransactionStatus(withdrawResult, 1, 1000, 300);
            if (!unstakeSuccess) {
              setUnstakeGiddyText('Unstake GIDDY');
              setDialogError('Unstake unsuccessful, transaction failed');
            }
            else {
              onClose('Successfully unstaked ' + formatTokenValue(amount, agg.info.tokenDecimals + 10) + ' Shares');
            }
          }
          setLoading(false);
        }
      }
    }
  }
  

  async function unstickClicked() {
    if (account && agg) {
      setCompoundText('Confirming');
      const unstickResult = await agg.unstickMoney();
      if (!ethers.utils.isHexString(unstickResult)) {
        setDialogError(unstickResult);
      }
      else {
        setCompoundText('Unsticking');
        const compoundSuccess = await agg.wallet.waitForTransactionStatus(unstickResult, 1, 1000, 300);
        if (!compoundSuccess) {
          setDialogError('Unstick unsuccessful, transaction failed');
        }
        else {
          setDialogSuccess('Giddy Up! Your money is now unstuck');
        }
      }
    }
    setCompoundText('Unstick Money');
  }

  let radioBox = (
    <div className='stake-box'>
      <div className='amount-box'>
        <TextField
          fullWidth
          label={"USDC Amount " + depositUsdcLabel}
          value={depositUsdcAmount}
          onChange={(e) => depositUsdcChange(e.target.value)}
          error={depositUsdcError.length > 0}
          helperText={depositUsdcError}
          disabled={loading}
        />
        <Button sx={{height: 56}} onClick={depositUsdcMaxClicked} disabled={loading}>Max</Button>
      </div>
      <LoadingButton 
        variant="contained" 
        fullWidth
        disabled={approveUsdcDisabled}
        loading={loading && !approveUsdcDisabled}
        loadingPosition="end"
        endIcon={approveUsdcText.startsWith('Approved') ? <CheckIcon/> : undefined}
        onClick={aprroveUsdcClicked}
        sx={{
          ':disabled': {
            'background': '#E2DFD9',
            'color': '#FFF'
          }
        }}
        >
        { approveUsdcText }
      </LoadingButton>
      <LoadingButton 
        variant="contained" 
        fullWidth 
        disabled={stakeUsdcDisabled}
        loading={loading && !stakeUsdcDisabled}
        loadingPosition="end"
        onClick={stakeUsdcClicked}
        sx={{
          ':disabled': {
            'background': '#E2DFD9',
            'color': '#FFF'
          }
        }}
        >
        { stakeUsdcText }
      </LoadingButton>
      <div className='amount-box'>
        <TextField
          fullWidth
          label={"GIDDY Amount " + depositGiddyLabel}
          value={depositGiddyAmount}
          onChange={(e) => depositGiddyChange(e.target.value)}
          error={depositUsdcError.length > 0}
          helperText={depositGiddyError}
          disabled={loading}
        />
        <Button sx={{height: 56}} onClick={depositGiddyMaxClicked} disabled={loading}>Max</Button>
      </div>
      <LoadingButton 
        variant="contained" 
        fullWidth 
        disabled={approveGiddyDisabled}
        loading={loading && !approveGiddyDisabled}
        loadingPosition="end"
        endIcon={approveGiddyText.startsWith('Approved') ? <CheckIcon/> : undefined}
        onClick={aprroveGiddyClicked}
        sx={{
          ':disabled': {
            'background': '#E2DFD9',
            'color': '#FFF'
          }
        }}
        >
        { approveGiddyText }
      </LoadingButton>
      <LoadingButton 
        variant="contained" 
        fullWidth 
        disabled={stakeGiddyDisabled}
        loading={loading && !stakeGiddyDisabled}
        loadingPosition="end"
        onClick={stakeGiddyClicked}
        sx={{
          ':disabled': {
            'background': '#E2DFD9',
            'color': '#FFF'
          }
        }}
        >
        { stakeGiddyText }
      </LoadingButton>
    </div>
  );

  if (radioText === 'unstake') {
    radioBox = (
      <div className='stake-box'>
        <div className='amount-box'>
          <TextField
            fullWidth
            label={"Shares " + withdrawLabel} 
            value={withdrawAmount} 
            onChange={(e) => withdrawChange(e.target.value)} 
            error={withdrawError.length > 0} 
            helperText={withdrawError} />
          <Button sx={{height: 56}} onClick={withdrawMaxClicked}>Max</Button>
        </div>
        <LoadingButton 
          variant="contained" 
          fullWidth 
          disabled={unstakeDisabled}
          loading={loading}
          loadingPosition="end"
          onClick={unstakeUsdcClicked}
          sx={{
            ':disabled': {
              'background': '#E2DFD9',
              'color': '#FFF'
            }
          }}
          >
          {unstakeUsdcText}
        </LoadingButton>
        <LoadingButton 
          variant="contained" 
          fullWidth 
          disabled={unstakeDisabled}
          loading={loading}
          loadingPosition="end"
          onClick={unstakeGiddyClicked}
          sx={{
            ':disabled': {
              'background': '#E2DFD9',
              'color': '#FFF'
            }
          }}
          >
          {unstakeGiddyText}
        </LoadingButton>
      </div>
    );
  }

  if (radioText === 'dev') {
    radioBox = (
      <div className='stake-box'>
        <LoadingButton 
          variant="contained" 
          fullWidth 
          loading={loading}
          loadingPosition="end"
          onClick={unstickClicked}
          sx={{
            ':disabled': {
              'background': '#E2DFD9',
              'color': '#FFF'
            }
          }}
          >
          {unstickText}
        </LoadingButton>
      </div>
    );
  }

  return (
    <Dialog fullWidth maxWidth="xs" onClose={() => { onClose(''); }} open={isOpen}>
      <IconButton onClick={() => { onClose(''); }} sx={{ position: 'absolute', right: 8, top: 8, }}>
        <CloseIcon />
      </IconButton>
      <div className="farm-dialog">
        <div>
          <a href={'https://polygonscan.com/address/' + agg?.info.address} style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer">
            {agg?.info.name}
          </a>
        </div>
        <div className="user-box">
          <div className="user-box-text">
            <div>USDC in Wallet: {formatTokenValue(availableUsdc, 6, false)}</div>
            <div>Giddy in Wallet: {formatTokenValue(availableGiddy, 18, false)}</div>
            <div>USDC Staked Value: {formatTokenValue(staked, 6, false)}</div>
            <div>Shares: {formatTokenValue(shares, (agg?.info.tokenDecimals ?? 0) + 10, false)}</div>
          </div>
        </div>
        <LoadingButton 
          className="compound" 
          variant="outlined"
          loading={compoundText !== 'Compound'}
          loadingPosition="end"
          fullWidth
          onClick={compoundClicked}>
          {compoundText}
        </LoadingButton>
        <RadioGroup row onChange={onRadioChange} defaultValue="stake">
          <FormControlLabel disabled={loading} value="stake" control={<Radio />} label="Stake" />
          <FormControlLabel disabled={loading} value="unstake" control={<Radio />} label="Unstake" />
          <FormControlLabel disabled={loading} value="dev" control={<Radio />} label="Dev" />
        </RadioGroup>
        {radioBox}
        {farmError && <div className="alert-box"><Alert severity="error">{farmError}</Alert></div>}
        {farmSuccess && <div className="alert-box"><Alert severity="success">{farmSuccess}</Alert></div>}
      </div>
    </Dialog>
  );
}
